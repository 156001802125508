import React from 'react'
import { Link } from 'gatsby'
const PrivacyPolicyComponent = () => {
  return (
    <>
      <div className="container mx-auto pb-0 pt-16 sm:py-16">
        <div className="float-right w-full lg:w-2/5 flex justify-end mb-8 lg:mb-0 lg:ml-16">
          <div className="w-11/12 sm:w-full lg:3/5 bg-white shadow-profileCard rounded-lg p-7 h-max mx-auto">
            <p className="text-voyage font-sans font-semibold text-lg mb-4">
              Jump To
            </p>
            <h1 className="font-sans font-bold text-xl mb-4">Privacy Policy</h1>
            <ul className="pl-0 ml-0">
              <li className="mb-2">
                <a href="#privacy_section_a">A. The information we gather</a>
              </li>

              <li className="mb-2">
                <a href="#privacy_section_b">
                  B. How we use the information we gather
                </a>
              </li>

              <li className="mb-2">
                <a href="#privacy_section_c">
                  C. How we share information we gather
                </a>
              </li>

              <li className="mb-2">
                <a href="#privacy_section_d">D. Tracking Technologies</a>
              </li>

              <li className="mb-2">
                <a href="#privacy_section_e">E. Security</a>
              </li>

              <li className="mb-2">
                <a href="#privacy_section_f">F. Surveys and Sweepstakes</a>
              </li>

              <li className="mb-2">
                <a href="#privacy_section_g">G. Social Media Features</a>
              </li>

              <li className="mb-2">
                <a href="#privacy_section_h">
                  H. Choice and Correction of personally identifiable
                  information
                </a>
              </li>

              <li className="mb-2">
                <a href="#privacy_section_i">
                  I. Changes to this Privacy Policy
                </a>
              </li>

              <li className="mb-2">
                <a href="#privacy_section_j">J. Questions?</a>
              </li>
            </ul>
            <h1 className="font-sans font-bold text-xl mb-4">Terms of Use</h1>
            <ul className="pl-0 ml-0 mb-0">
              <li className="mb-2">
                <a href="#legal_information">Legal Information</a>
              </li>
              <li className="mb-2">
                <a href="#ownership">Ownership; Proprietary Notices</a>
              </li>
              <li className="mb-2">
                <a href="#warranty">Warranty Information; Disclaimer</a>
              </li>
              <li className="mb-2">
                <a href="#limitation">Limitation of Liability</a>
              </li>
              <li className="mb-2">
                <a href="#acknowledgement">Acknowledgement</a>
              </li>
              <li className="mb-2">
                <a href="#void">Void Where Prohibited; Indemnification</a>
              </li>
              <li className="mb-2">
                <a href="#code">Code of Conduct</a>
              </li>
              <li className="mb-2">
                <a href="#restricted_rights_legend">Restricted Rights Legend</a>
              </li>
              <li className="mb-2">
                <a href="#making_purchases">Making Purchases</a>
              </li>
              <li className="mb-2">
                <a href="#submissions">Submissions</a>
              </li>
              <li className="mb-2">
                <a href="#registeration">
                  Registration; Use of Secure Areas and Passwords
                </a>
              </li>
              <li className="mb-2">
                <a href="#claims_infringement">
                  Claims of Copyright Infringement
                </a>
              </li>
              <li className="mb-2">
                <a href="#miscellaneous">Miscellaneous</a>
              </li>
              <li className="mb-2">
                <a href="#legal_notices">Legal Notices</a>
              </li>
              <li className="mb-2">
                <a href="#additional_terms">
                  Additional Terms for Use of Additional Services
                </a>
              </li>
              <li className="mb-2">
                <a href="#advertisers">
                  Advertisers (Employers and Recruiters)
                </a>
              </li>
              <li className="mb-2">
                <a href="#advertising_campaigns">Advertising Campaigns</a>
              </li>
              <li className="mb-2">
                <a href="#candidates">
                  Candidates (collectively called Members)
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className="px-6 sm:px-0 pt-12">
            <p className="t-normal font-sans text-sm pt-2">
              MedReps.com, a BioSpace, Inc. company (“MedReps”), and BioSpace,
              Inc. (“BioSpace”) are committed to safeguarding the privacy of our
              users while providing personalized career management services on
              our websites. This Privacy Policy describes our practices on the
              collection, use and disclosure of your information.  When used
              herein, the terms "we," "us," and "our website" refer to
              www.medreps.com (the “Website”). MedReps provides links to the
              websites of many other companies to which this policy does not
              apply. Other than the requirements to which we adhere under the
              Privacy Shield framework and its accountability for onward
              transfer of personal information, we cannot, and do not, assume
              responsibility for the privacy or information policies, practices
              or actions of any third parties.
            </p>
            <p className="t-normal font-sans text-sm">
              We at MedReps are committed to good privacy practices regarding
              your personally identifiable information. We acknowledge and
              commit to complying with all data protection laws, including the
              General Data Protection Regulation (GDPR) and the California
              Consumer Privacy Act (CCPA). Your loyalty as a jobseeker, customer
              or visitor is important, and we hope that our concerns regarding
              your privacy, as described in this policy (the "Privacy Policy" or
              "Policy"), demonstrate our commitment to making your experience
              with MedReps an enjoyable and satisfying one.
            </p>
            <p className="t-normal font-sans text-sm">
              This Privacy Policy governs your interaction with the Sites and
              your registration for and use of our online services
              (collectively, the "Services"). Except as set forth within this
              Privacy Policy and our Terms of Use Agreement and other published
              guidelines, we do not release personally identifiable information
              about our users and visitors without their permission. This
              Privacy Policy only applies to information collected by the Sites.
              Accordingly, we encourage you to review the entire Privacy Policy.
            </p>
          </div>
        </div>
        <div className="px-6 sm:px-0 pt-12">
          <div className="mb-2" id="privacy_section_a">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              A. The information we gather
            </p>

            <p className="t-normal font-sans text-sm">
              Personally identifiable information: In certain areas of the
              Sites, such as those in which you complete a profile, click to
              apply for a job, submit your resume, create a new account, or
              enter MedReps-sponsored contests or promotions, we may request
              that you provide us with certain personally identifiable
              information, such as your e-mail address, name, zip code, phone
              number, profession, company name, and work experience. We will, in
              such situations, seek to identify which informational items are
              required and which are optional. In some instances, we may collect
              geo-location based information for the purpose of helping identify
              jobs in your area. We may share this information for the sole
              purpose of providing you this feature. You may opt-out of location
              based services by editing the setting at the device level.
            </p>
            <p className="t-normal font-sans text-sm">
              We may also keep personally identifiable commercial data,
              including a record of services purchase through MedReps, to ensure
              service delivery.
            </p>
            <p className="t-normal font-sans text-sm">
              Professionals should post profiles and resumes on our Sites only
              if they wish such information to be viewed and downloaded by
              MedReps employers and recruiters. At any time, job seekers may
              elect that their profile and resume not be searchable by our
              customers by logging into their account and changing the status of
              their profile or, if that is not possible, they may contact us at
              info@medreps.com and we will make the change for them.
            </p>
            <p className="t-normal font-sans text-sm">
              MedReps Services are not available to children under 16 years of
              age. We do not knowingly collect personally identifiable
              information from children under 16 years of age. Please note that
              we cannot control the acts of users of the Services. All members
              and visitors should be aware that, when they disclose personally
              identifiable information in a resume or profile or other medium,
              the information may be collected and used by others to send that
              person unsolicited email from other parties outside MedReps. In
              the event that you encounter any MedReps user who is improperly
              collecting or using information about you or other users, please
              contact info@medreps.com
            </p>
          </div>
          <div className="mb-2" id="privacy_section_b">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              B. How we use the information we gather
            </p>
            <p className="t-normal font-sans text-sm">
              Please take some time to familiarize yourself with the different
              ways we use the information we gather. MedReps encourages all
              third parties involved, including but not limited to its
              advertisers, to adhere to our policies regarding the privacy of
              our visitors and users and to otherwise handle personally
              identifiable information in a responsible manner. Nevertheless, in
              the event you encounter any third party associated with, or who
              claims association with MedReps, who you feel is improperly
              collecting or using information about you, please
              contact info@medreps.com.
            </p>
            <p className="t-normal font-sans text-sm">
              1.            How we use non-personally identifiable information:
              We use non-personally identifiable information in aggregate form
              to build higher quality, more useful Services by performing
              statistical analyses of the collective characteristics and
              behavior of our visitors and users, and by measuring demographics
              and interests regarding specific areas of the Services. We may
              provide statistical information based on this data to advertisers,
              users, and other current and potential business partners. We may
              also use the aggregate data to inform these third parties as to
              the number of people who have seen and clicked through links to
              their sites.
            </p>
            <p className="t-normal font-sans text-sm">
              2.            How we use personally identifiable information: We
              may use the personally identifiable information a user submits for
              any purposes related to MedReps business, including, but not
              limited:
              <ul>
                <li>
                  • To understand a user's needs and create content that is
                  relevant to the user;
                </li>
                <li>• To generate statistical studies;</li>
                <li>
                  • To conduct market research and planning by sending user
                  surveys;
                </li>
                <li>
                  • To notify user referrals of MedReps services, information,
                  or products when a user requests that MedReps send such
                  information to referrals;
                </li>
                <li>• To improve services, information, and products;</li>
                <li>
                  • To help a user complete a transaction, or provide services
                  or customer support to users and to recipients of MedReps
                  products;
                </li>
                <li>
                  • To communicate with the user and with recipients of MedReps
                  products and services;
                </li>
                <li>
                  • To email users about services, information, products and
                  offers from our business partners (and as set forth in section
                  C, How we share information we gather);
                </li>
                <li>• To personalize the Sites for the user;</li>
                <li>
                  • To notify the user of any changes with the Sites which may
                  affect the user;
                </li>
                <li>• To enforce terms of use on the Sites;</li>
                <li>
                  • To allow the user to purchase products, access services, or
                  otherwise engage in activities the user selects; and
                </li>
                <li>
                  • To allow us to contact you by phone, email and/or social
                  media. In some cases, this includes text messaging or alerts
                  and carrier charges may apply.
                </li>
              </ul>
            </p>
            <p className="t-normal font-sans text-sm">
              User names, identifications ("IDs"), and email addresses (as well
              as any additional information that a user may choose to post) may
              be publicly available on the Sites when a user voluntarily and
              publicly discloses personally identifiable information, such as
              when a user uses features to send a message to another user, or as
              part of a message posted to a public forum or a publicly-released
              software application. Users may not be able to change or remove
              public postings once posted. Such personally identifiable
              information may be used by visitors of these pages to send
              unsolicited messages. MedReps is not responsible for any
              consequences which may occur from the use of personally
              identifiable information that a user chooses to submit to public
              pages.
            </p>
            <p className="t-normal font-sans text-sm">
              With respect to surveys, in the event that responses are publicly
              disclosed, users will be notified at the time they take the
              survey. MedReps will disclose only aggregate information regarding
              its users, i.e., anonymous information that does not identify any
              specific individual. Circumstances under which MedReps would
              publicly disclose such aggregate information include, but are not
              limited to, sharing survey results with the Sites' users,
              providing data to MedReps advertisers on user preferences and/or
              demographics, and publicizing overall usage data in press
              communications. Where surveys allow users to submit written
              comments, and where MedReps advises users of the possibility of
              such disclosure at the time they take the survey, MedReps reserves
              the right to disclose any information provided by users, provided
              that no personally identifiable information identifying a specific
              user is disclosed. Participation in surveys is at a user's option;
              MedReps does not conduct mandatory surveys.
            </p>
          </div>
          <div className="mb-2" id="privacy_section_c">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              C. How we share information we gather
            </p>
            <p className="t-normal font-sans text-sm">
              Subject to the foregoing, MedReps uses your personally
              identifiable information in several ways. MedReps may share your
              personally identifiable information with service providers to help
              us with our business activities such as processing credit cards,
              email and social network marketing, offering customer service, or
              operating a community forum. These service providers are
              authorized to use your personally identifiable information only as
              necessary to provide these services to us.
            </p>
            <p className="t-normal font-sans text-sm">
              With respect to job applications and our resume database service,
              MedReps will not disclose your profile or resume on our Sites to
              prospective employers and recruiters without your consent or to
              carry out your instructions.
            </p>
            <p className="t-normal font-sans text-sm">
              If you "opt in" to receive promotional materials from third
              parties, information you have made available to us may be made
              available to third parties who provide goods or services that we
              believe may be of interest to you. You may "opt out" of receiving
              such materials at any time in a variety of ways: by using the
              "opt-out" link included in each email, by changing your
              subscriptions preferences on the "Your subscriptions" page in your
              MedReps account or by contacting us at info@medreps.com. If you
              have provided more than one email address to our service, you may
              continue to be contacted, unless you request to unsubscribe each
              email address you have provided. If you opted in to receive
              information directly from third parties, then you must contact
              those third parties directly to opt-out.
            </p>
            <p className="t-normal font-sans text-sm">
              In addition, MedReps reserves the right to share aggregate
              information collected from users of the Sites, without prior
              notice, with entities that are part of the MedReps corporate
              family and unrelated third parties. Aggregate information is used
              in a collective manner and no single person can be identified by
              such compiled information.
            </p>
            <p className="t-normal font-sans text-sm">
              To provide services to our customers, MedReps collects indexed
              content from a variety of publicly available websites. Indexed
              content may include personally identifiable information such as
              posts on blogs. In general, such indexed content is available
              through standard search engines or by visiting the websites
              themselves. MedReps may append this information to your profile or
              use this content to create a profile, whether or not an individual
              has an account with us. If you would like to remove the
              information we collect, see section I to submit your request.
              MedReps has no ownership rights to the indexed content sourced
              from third party sites and cannot guarantee that we will not
              collect information from a publicly available source at a later
              point in time.
            </p>
            <p className="t-normal font-sans text-sm">
              Please note that MedReps reserves the right to disclose
              information submitted by or concerning any visitor or user as we
              reasonably feel is necessary to protect our systems or business.
              We may also disclose your personally identifiable information as
              required by law, such as to comply with a subpoena or other legal
              process, when we believe in good faith that disclosure is
              necessary to protect our rights, protect your safety or the safety
              of others, investigate fraud, or respond to a government request.
              We cooperate with law enforcement agencies in identifying those
              who may be using our servers or Services for illegal activities.
              We also reserve the right to report any suspected illegal activity
              to law enforcement for investigation or prosecution, or to suspend
              or terminate your use of the Services in connection with any
              suspected illegal or infringing activity or if you are in
              violation of our Terms of Use Agreement or other published
              guidelines.
            </p>
            <p className="t-normal font-sans text-sm">
              MedReps reserves the right to assign, sell, license, or otherwise
              transfer to a third party your personally identifiable information
              in connection with an assignment, sale, joint venture, or other
              transfer or disposition of any portion or all of MedReps 's or its
              affiliated entities' assets or stock.
            </p>
          </div>
          <div className="mb-2" id="privacy_section_d">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              D. Tracking Technologies
            </p>
            <p className="t-normal font-sans text-sm">
              Technologies such as cookies, beacons, tags and scripts are used
              by MedReps and our partners, analytics, and service providers.
              These technologies are used generally for analyzing trends,
              administering the site, tracking users’ movements around the site
              and to gather demographic information about our user base as a
              whole.
            </p>
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Pixels
            </p>
            <p className="t-normal font-sans text-sm">
              We use pixels, or transparent GIF files, to help manage online
              advertising. These pixels are provided by our ad management
              partner, Google Ad Manager. These files enable our ad management
              partner to recognize a unique cookie on your Web browser, which in
              turn enables us to learn which advertisements bring users to our
              Sites. The information that we collect and share is anonymous and
              not personally identifiable. It does not contain your name,
              address, telephone number, or email address. For more information
              about Google Ad Manager, including information about how to opt
              out of these technologies, go to
              http://www.google.com/privacy/ads/ or you may also opt out by
              contacting info@medreps.com
            </p>
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Framing
            </p>
            <p className="t-normal font-sans text-sm">
              The Sites may utilize framing and masking techniques to make it
              appear that the user is still on the Sites when the user is
              actually on a third party website. We also have co-branding
              relationships with certain partners. In these relationships, you
              will notice our service platform on our partner's page. In this
              situation, both parties are collecting your data and both privacy
              policies govern the data collected. We will not share your data
              with third parties without your consent in these and other
              relationships. We encourage you to be aware when you leave our
              Sites and to read the privacy policies of each and every website
              that collects personally identifiable information.
            </p>
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Web beacons
            </p>
            <p className="t-normal font-sans text-sm">
              We use web beacons from time to time. A web beacon is a string of
              code that provides a method for delivering a graphic image on a
              web page or in an email message for the purpose of transferring
              data, or determining how many times a specific web page has been
              viewed. Web beacons enable MedReps to recognize a browser's cookie
              when a browser visits a Site, and to learn which banner ads bring
              users to each Site. For example, when a user visits a web page,
              the code for the page may include instructions to go to another
              server to gather a single pixel graphic image. Web beacons are not
              placed on a user's computer. Such web beacons may be provided by
              MedReps' third party advertising companies to help manage and
              optimize any online advertising.
            </p>
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Cookies
            </p>
            <p className="t-normal font-sans text-sm">
              We use cookies on the Sites. A "browser" or "HTTP" cookie is a
              unique text file that may be used for data analysis, and enables a
              web site to tailor information presented to a user based on a
              user's browsing program. MedReps may use browser cookies to
              personalize a user's pages at our Sites, to remember a user when
              the user registers for products or services, for fraud prevention,
              or to track visits to our Sites. If a user does not want MedReps
              or its partners to deploy browser cookies in the user's browser
              when the user visits the Sites, the user may set the browser to
              reject cookies or to notify the user when a web site tries to
              place cookies in the browser program. Rejecting cookies may affect
              a user's ability to use some of the products, features, functions,
              or services on our Sites.
            </p>
            <p className="t-normal font-sans text-sm dont-break-out">
              We also may use local shared objects, also known as "Flash
              cookies" and local storage such as HTML5 cookies to store your
              preferences or display content based upon what you view on our
              website to personalize your visit. Flash cookies are different
              from "browser" cookies because of the amount of, type of, and how
              data is stored. Cookie management tools provided by your browser
              will not remove Flash cookies. To learn how to manage privacy and
              storage settings for Flash cookies, including how to delete Flash
              cookies, click here for additional
              information: http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html#117118.
            </p>
            <p className="t-normal font-sans text-sm">
              The ads appearing on the Sites may be delivered to visitors by
              third parties, such as Google. The third party advertising
              technology that MedReps uses on the Sites uses information derived
              from a user's visits to the Sites to target advertising within the
              Sites. In addition, MedReps’ advertisers may use other third party
              advertising technology to target advertising on the Sites. In the
              course of serving advertisements to the Sites, our partners may
              place or recognize a unique cookie on a user's browser.
              Information about users' visits to the Sites, such as the number
              of times users have viewed an ad (but not users' names, addresses,
              or other personally identifiable information), are used to serve
              ads to visitors. The use of cookies enables our partners to serve
              ads to users based on such user's visit to the Sites and other
              sites on the Internet. As with other cookies, and consistent with
              MedReps’ policy on cookies stated above, the user may block or
              delete such cookies from the user's drive or memory. For more
              information about Google, Google's use of cookies, and how to "opt
              out" of Google's email/information lists, please click
              here: http://www.google.com/privacy_ads.html.  If you wish to not
              have this information used for the purpose of serving you
              interest-based ads, you may opt-out by clicking here (or if
              located in the European Union click here) For our European
              customers you may opt-out here. Please note this does not opt you
              out of being served ads. You will continue to receive generic ads.
            </p>
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Log files
            </p>
            <p className="t-normal font-sans text-sm">
              MedReps web servers may automatically log aggregate information,
              such as a user's IP address, domain name, browser type, date and
              time of access, and other log file data.
            </p>
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Links to third party web sites
            </p>
            <p className="t-normal font-sans text-sm">
              Links to third party websites or widgets to enable sharing of
              information through social networks on the Sites are provided
              solely as a convenience to the user. When a user uses these links,
              the user leaves the Sites. MedReps has not reviewed all of these
              third party sites, does not control, and is not responsible for,
              any of the third party sites, their content or privacy practices.
              MedReps does not endorse or make any representations about the
              third party sites, or any information, services, or products found
              on the sites. If a user decides to access any of the linked sites,
              MedReps encourages the user to read their privacy statements. The
              user accesses such sites at user's own risk.
            </p>
          </div>
          <div className="mb-2" id="privacy_section_e">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              E. Security
            </p>
            <p className="t-normal font-sans text-sm">
              The security of your personally identifiable information is
              important to us. When you enter sensitive information (such as a
              credit card number) on our order forms, we encrypt the
              transmission of that information.
            </p>
            <p className="t-normal font-sans text-sm">
              We follow generally accepted standards to protect the personally
              identifiable information submitted to us, both during transmission
              and once we receive it. No method of transmission over the
              Internet, or method of electronic storage, is 100% secure,
              however. Therefore, we cannot guarantee that information may not
              be accessed, disclosed, altered, or destroyed by breach of any of
              our physical, technical, or managerial safeguards. It is your
              responsibility to protect the security of your login information.
              Please help keep your account safe by using a strong password.
              Accordingly, we advise that you do not communicate any
              confidential information through our Services.
            </p>
            <p className="t-normal font-sans text-sm">
              If you have any questions about security on our Website, you can
              contact us at info@medreps.com
            </p>
          </div>

          <div className="mb-2" id="privacy_section_f">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              F. Surveys and Sweepstakes
            </p>
            <p className="t-normal font-sans text-sm">
              Occasionally, MedReps may conduct surveys in order to better
              deliver advertisements and content and to better understand your
              needs relating to the Services. We will not share your survey
              information with any third party, except in aggregate form.
            </p>
            <p className="t-normal font-sans text-sm">
              From time-to-time we may provide you the opportunity to
              participate in contests or surveys on our site. If you
              participate, we will request certain personally identifiable
              information from you. Participation in these surveys or contests
              is completely voluntary and you therefore have a choice whether or
              not to disclose this information. The requested information
              typically includes contact information (such as name and shipping
              address), and demographic information (such as zip code). That
              information, to the extent it conflicts with this policy, will
              govern that particular promotion or sweepstakes - please remember
              to read it carefully. We may use this information to notify
              contest winners and award prizes, to monitor the Sites use, to
              personalize the Sites, to send participants an email, and to
              measure participants' demographics, interests and opinions. We may
              use a third party service provider to conduct these surveys or
              contests; that company will be prohibited from using our users'
              personally identifiable information for any other purpose. We will
              not share the personally identifiable information you provide
              through a contest or survey with other third parties unless we
              give you prior notice and choice.
            </p>
            <p className="t-normal font-sans text-sm">
              If you choose to use our referral service to tell another person
              about a job, we will ask you for that person's email address. We
              will automatically send that person a one-time email inviting him
              or her to visit the site. We use this information for the sole
              purpose of sending this one-time email, and do not store these
              email addresses.
            </p>
          </div>

          <div className="mb-2" id="privacy_section_g">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              G. Social Media Features
            </p>
            <p className="t-normal font-sans text-sm">
              The Sites include social media features, such as the Facebook like
              button and widgets, that run on our Sites. These features may
              collect your IP address, which page you are visiting on our Sites,
              and may set a cookie to enable the Feature to function properly.
              Social Media Features and Widgets are either hosted by a third
              party or hosted directly on our Site. Your interactions with these
              features are governed by the privacy policy of the company
              providing it.
            </p>
          </div>

          <div className="mb-2" id="privacy_section_h">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              H. Choice and Correction of personally identifiable information
            </p>
            <p className="t-normal font-sans text-sm">
              If you would like to have your personally identifiable information
              removed from MedReps’ database, or would like to correct an error
              in such information, please contact us at info@medreps.com and we
              will use reasonable efforts to comply with your request. Keep in
              mind, however, that there will be residual information that will
              remain within MedReps’ databases, access logs, and other records,
              which may or may not contain such personally identifiable
              information. If you have provided more than one email address to
              our service, you may continue to be contacted, unless you request
              to unsubscribe each email address you have provided.
            </p>
            <p className="t-normal font-sans text-sm">
              Choice and Access: Upon request MedReps will provide you with
              information about whether we hold any of your personal
              information. If your personally identifiable information changes,
              or if you no longer desire our service, you may correct, update,
              amend, delete/remove or deactivate it by making the change on your
              account page or by emailing our Customer Support at
              info@medreps.com or by contacting us by telephone or postal mail
              at the contact information listed below. We will respond to all
              access requests within 30 days.
            </p>
            <p className="t-normal font-sans text-sm">
              Data Retention: We will retain your information for as long as
              your account is active or as needed to provide you Services. If no
              account activity occurs for 3 years, we reserve the right to
              remove your account and all associated information. If you wish to
              cancel your account or request that we no longer use your
              information to provide you Services, contact us
              at info@medreps.com. We will retain and use your information as
              necessary to comply with our legal obligations, resolve disputes,
              and enforce our agreements.
            </p>
            <p className="t-normal font-sans text-sm">
              Blogs: The Sites may offer publicly accessible blogs or community
              forums. You should be aware that any information you provide in
              these areas may be read, collected, and used by others who access
              them. To request removal of your personally identifiable
              information from our blog or community forum, contact us
              at info@medreps.com. In some cases, we may not be able to remove
              your personally identifiable information, in which case we will
              let you know if we are unable to do so and why.
            </p>
            <p className="t-normal font-sans text-sm">
              Testimonials: We post testimonials on the Sites which may contain
              personally identifiable information. We do obtain consent by email
              or a release agreement prior to posting the testimonial to post
              their name along with their testimonial.
            </p>
          </div>

          <div className="mb-2" id="privacy_section_i">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              I. Changes to this Privacy Policy
            </p>
            <p className="t-normal font-sans text-sm">
              We may update this privacy policy to reflect changes to our
              information practices. If we make any material changes we will
              notify you by email (sent to the e-mail address specified in your
              account) or by means of a notice on the Site prior to the change
              becoming effective. We encourage you to periodically review this
              page for the latest information on our privacy practices.
            </p>
            <p className="t-normal font-sans text-sm">
              Note that, by using the Services, you signify your agreement to
              this Privacy Policy. If you do not agree to this Policy, please do
              not use the Services. Your continued use of the Services
              subsequent to changes to this Policy will mean that you accept the
              changes; therefore, please bookmark this page and review it
              periodically. If you have any questions regarding this Privacy
              Policy, or any other privacy issues in connection with the
              Services, please e-mail us at info@medreps.com
            </p>
          </div>

          <div className="mb-2" id="privacy_section_j">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              J. Questions?
            </p>
            <p className="t-normal font-sans text-sm">
              Questions regarding this Privacy Policy should be directed
              to info@medreps.com You may also contact us at MedReps, a
              BioSpace, Inc. company, 3001 Westown Parkway, Suite 101, West Des
              Moines, IA 50266, or 866-619-1629.{' '}
            </p>
          </div>

          <div className="flex justify-between items-center">
            <h1 className="font-playfair font-headingOne font-semibold text-merlot">
              Terms & Conditions
            </h1>
            <p className="text-gray-400 font-sans italic text-base mb-0">
              Last Updated: January 2023
            </p>
          </div>

          <div className="mb-2" id="legal_information">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Legal Information
            </p>
            <p className="t-normal font-sans text-sm">
              MedReps.com, a BioSpace, Inc. company (“MEDREPS”, “COMPANY”, “WE”,
              “US”) provides this web site and all site-related services,
              including the content and code related thereto (collectively, the
              "site"), subject to your compliance with the terms and conditions
              set forth below. please read this Terms of Use agreement
              ("agreement") carefully before using this site. By using the Site,
              you agree to be bound by these terms and conditions. If you do not
              agree to these terms and conditions, please do not use the Site.
            </p>
            <p className="t-normal font-sans text-sm">
              This Agreement is made between the Company and you, the Site
              visitor and/or registered user ("you"). We reserve the right at
              any time to:
            </p>
            <ul>
              <li> • Change the terms and conditions of this Agreement;</li>
              <li>
                • Change the Site, including eliminating or discontinuing any
                content on or feature of the Site, restricting the hours of
                availability or limiting the amount of use permitted; or
              </li>
              <li>
                • Change any fees or charges for use of the Site, including
                instituting new or increased fees or charges for the use of the
                Site or any other Site-related services or any feature thereof.
              </li>
            </ul>
            <p className="t-normal font-sans text-sm">
              Any changes we make will be effective immediately upon notice,
              which we may provide by any means including, without limitation,
              posting on the Site or via electronic mail. Your use of the Site
              after such notice will be deemed acceptance of such changes. Be
              sure to review this Agreement periodically to ensure familiarity
              with the most current version. Upon our request, you agree to sign
              a non-electronic version of this Agreement.
            </p>
            <p className="t-normal font-sans text-sm">
              You must be at least 16 years of age to use the Site.
            </p>
          </div>
          <div className="mb-2" id="ownership">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Ownership; Proprietary Notices
            </p>
            <p className="t-normal font-sans text-sm">
              The Site, including all pages within and all code related thereto,
              is the property of MedReps.  No portion of the materials or code
              on these pages or anywhere on the Site may be reprinted or
              republished (other than as is necessary to view the page on your
              monitor) in any form without the express written permission of the
              Company.
            </p>
            <p className="t-normal font-sans text-sm">
              MedReps® is a registered service mark of the Company. The
              following are trademarks and/or service marks owned by the Company
              that are not yet registered in the United States: the MedReps
              logo. All other trademarks and/or service marks used in this Site
              are the trademarks and/or service marks of their respective
              owners.
            </p>
            <p className="t-normal font-sans text-sm">
              The Site is owned and operated by us in conjunction with others
              pursuant to contractual arrangements. Unauthorized use of the
              materials on the Site may violate copyright, trademark, patent and
              other laws and is prohibited. You acknowledge that you do not
              acquire any ownership rights by using the Site.
            </p>
          </div>
          <div className="mb-2" id="warranty">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Warranty Information; Disclaimer
            </p>
            <p className="t-normal font-sans text-sm">
              This site, including any content or information contained within
              it or any site related service, or any product or service
              licensed, purchased or otherwise made available through the site,
              is provided "as is" with no representations or warranties of any
              kind, either expressed or implied. To the fullest extent
              permissible pursuant to applicable law, MedReps and its
              affiliates, suppliers, sponsors, and agents disclaim all
              warranties, express or implied, including, but not limited to, the
              implied warranties of title, merchantability, fitness for a
              particular purpose and non-infringement. Neither MedReps,
              affiliates, sponsors, suppliers nor agents makes any
              representation as to the results to be obtained from use of the
              site or the site-related services
            </p>
            <p className="t-normal font-sans text-sm">
              MedReps and its affiliates, suppliers, and agents do not warrant
              that your use of the site or the software will be uninterrupted,
              error-free, or secure, that defects will be corrected, or that the
              site (or the server(s) on which it is hosted) or software are free
              of viruses or other harmful components. You acknowledge that you
              are responsible for obtaining and maintaining all telephone,
              computer hardware and other equipment needed to access and use the
              site, and all charges related thereto. You assume total
              responsibility and risk for your use of the site and the software
              and your reliance thereon. No opinion, advice, or statement of
              MedReps or its affiliates, suppliers, agents, registered users, or
              visitors, whether made on the site, or otherwise, shall create any
              warranty. Your use of the site and any materials provided through
              the site are entirely at your own risk.
            </p>
            <p className="t-normal font-sans text-sm">
              You acknowledge that the Site could include inaccuracies or
              errors, or materials that violate these Terms of Use
              (specifically, the "Code of Conduct" section). Additionally, you
              acknowledge that unauthorized additions, deletions and alterations
              could be made by third parties to the Site. Although the Company
              attempts to ensure the integrity and the accuracy of the Site, it
              makes no guarantees whatsoever as to the Site's completeness or
              correctness. In the event that such a situation arises, please
              contact us at info@medreps.com with, if possible, a description of
              the material to be checked and the location (URL) where such
              material can be found on the Site, as well as information
              sufficient to enable us to contact you. We will try to address
              your concerns as soon as reasonably practicable. For copyright
              infringement claims, see the section on "Claims of Copyright
              Infringement."
            </p>
            <p className="t-normal font-sans text-sm">
              The Site is a digital media platform allowing for recruitment
              marketing and advertising by employers, recruiters, and agencies
              of available job opportunities and employer/product branding at
              their companies or companies they represent (hereinafter referred
              to collectively by “employers” and by candidates of their own
              profiles and resumes.”  The Company does not evaluate or censor
              the resumes, job listings or other information posted to the Site.
              Moreover, the Company is not involved in the actual transaction,
              if any, between potential employers and candidates. Consequently,
              we have no control over the quality, safety or legality of the job
              listings, resumes or advertising posted to the Site, the truth or
              accuracy of such job listings, resumes, or advertising, the
              ability of employers to hire candidates or the ability of
              candidates to fill job openings.
            </p>
            <p className="t-normal font-sans text-sm">
              Because user authentication on the Internet is difficult, the
              Company cannot and does not confirm that users are who they claim
              to be. Because we do not and cannot be involved in user-to-user
              transactions or control the behavior of the Site's users, in the
              event that you have a dispute with one or more Site users, you
              release the Company (and its affiliates, agents and employees)
              from all claims, demands and damages (actual and consequential,
              direct and indirect) of any kind and nature, known and unknown,
              suspected and unsuspected, disclosed and undisclosed, arising out
              of or in any way related to such disputes. You acknowledge that
              there may be certain risks, including but not limited to the risk
              of physical harm and of dealing with strangers, underage persons
              or people acting under false pretenses, associated with pursuing
              relationships begun on the Internet. You assume all risks related
              to dealing with other users with whom you have had contact through
              the Site.
            </p>
            <p className="t-normal font-sans text-sm">
              You acknowledge and agree that you are solely responsible for the
              form, content and accuracy of any resume, job listing or other
              material you post to the Site. The Company neither warrants nor
              guarantees that a resume or job posting will be viewed by any
              specific number of users, or that a resume or job listing will be
              viewed by any user. We are not to be considered as an employer
              with respect to your use of the Site and we shall not be
              responsible for any employment decisions made by any entity
              posting job listings or resumes to the Site.
            </p>
          </div>
          <div className="mb-2 hidden sm:block" id="limitation">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Limitation of Liability
            </p>
            <p className="t-normal font-sans text-sm">
              Neither the company nor its affiliates, suppliers, advertisers,
              agents or sponsors are responsible or liable for any indirect,
              incidental, consequential, special, exemplary, punitive or other
              damages under any contract, negligence, strict liability or other
              theory arising out of or relating in any way to the site and/or
              content contained on the site, or any product or service purchased
              through the site. Your sole remedy for dissatisfaction with the
              site and/or content contained within the site is to stop using the
              site. The sole and exclusive maximum liability to company for all
              damages, losses, and causes of action (whether in contract, tort,
              including, without limitation, negligence or otherwise) shall be
              the total amount paid by you, if any, to access the site.
            </p>
          </div>
          <div className="mb-2" id="acknowledgement">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Acknowledgement
            </p>
            <p className="t-normal font-sans text-sm">
              This Site may contain links to other web sites operated by third
              parties, other than affiliates of the Company ("Linked Sites"). We
              neither control nor endorse such other web sites, nor have we
              reviewed or approved any content that appears on the Linked Sites.
              You acknowledge that when you click on a link to visit a Linked
              Site, a frame may appear that contains the Company logo,
              advertisements and/or other content selected by the Company. You
              acknowledge that the Company and its sponsors neither endorse nor
              are affiliated with the Linked Sites and are not responsible for
              any content that appears on the Linked Sites. You also acknowledge
              that the owner of the Linked Site neither endorses nor is
              affiliated with the Company and its sponsors.
            </p>
          </div>
          <div className="mb-2" id="void">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Void Where Prohibited; Indemnification
            </p>
            <p className="t-normal font-sans text-sm">
              Although the Site is accessible worldwide, not all products or
              services discussed, referenced or made available on the Site are
              available to all persons or in all geographic locations or
              jurisdictions. We make no representation that materials in the
              Site are appropriate or available for use in locations outside the
              United States. Those who choose to access the Site from other
              locations do so on their own initiative and at their own risk and
              are responsible for compliance with local laws if and to the
              extent local laws are applicable. The Company reserves the right
              to limit the availability of the Site and/or the provision of any
              product or service described thereon to any person, geographic
              area, or jurisdiction it so desires at any time and in our sole
              discretion and to limit the quantities of any such product or
              service that we provide.
            </p>
            <p className="t-normal font-sans text-sm">
              You agree to indemnify, defend and hold us, our affiliates,
              sponsors, officers, directors, employees, agents and
              representatives harmless from and against any and all claims,
              damages, losses, costs (including reasonable attorneys' fees), or
              other expenses that arise directly or indirectly out of or from
              (a) your breach of this Agreement, (b) your violation of the Code
              of Conduct, and/or (c) your activities in connection with the Site
              or Site-related services.
            </p>
          </div>
          <div className="mb-2" id="code">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Code of Conduct
            </p>
            <p className="t-normal font-sans text-sm">
              While using the Site or Site-related services, you agree not to do
              any of the following without our prior written authorization:
              <ul>
                <li>
                  • Post any inaccurate, untimely, stale, incomplete or
                  misleading information.
                </li>
                <li>
                  • Post any employment opportunity or requirements that are
                  inaccurate or not available with a verifiable company that is
                  directly represented by your office.
                </li>
                <li>
                  • Post any employment opportunities that are not medical
                  sales-related, or are outside a medical sales-related field or
                  organization.
                </li>
                <li>
                  • Key code (by overuse of a skill set, term or definition
                  within a job posting, profile, resume or otherwise in an
                  effort to gain priority placement of a posting), copy other
                  job postings as your own, post the same position multiple
                  times on the Site or post multiple positions within one
                  posting.
                </li>
                <li>
                  • Place any contact information within a job posting that is
                  different from or in addition to contact information provided
                  when subscribing to the Company service.
                </li>
                <li>
                  • Post any employment opportunity that does not contain a
                  valid application method, such as company URL, e-mail address
                  or "apply online" that is connected to the "Apply Now" button
                  in the job posting.
                </li>
                <li>
                  • Place any content within the job details of a job posting
                  that directs MedReps job seekers to apply directly via an
                  email address or any other method that circumvents the "Apply
                  Now" link.
                </li>
                <li>
                  • Resell or make available to any person not expressly
                  licensed by MedReps, whether via phone, fax, e-mail, mail or
                  any other medium, any of the MedReps services or any
                  information obtained therefrom, including without limitation
                  resumes or candidate information.
                </li>
                <li>
                  • Share passwords, login information or named user
                  identification or otherwise allow multiple offices or users to
                  access the MedReps service on a basis that is other than what
                  was originally subscribed for. A named user is defined as one
                  unique individual user with one unique password. An office is
                  defined as a location where a named user routinely accesses or
                  uses the MedReps service under express license from MedReps.
                </li>
                <li>
                  • Use any search engine, software, tool, electronic storage or
                  retrieval device, agent or other device or mechanism,
                  including without limitation browsers, spiders, robots,
                  avatars or intelligent agents (collectively "Devices") that is
                  not approved by Company to navigate, search or store
                  information from the Site. Approved Devices include those made
                  available on the Site, or other generally available third
                  party web browsers, e.g., Mozilla Firefox, Google Chrome,
                  Microsoft Internet Explorer, Apple Safari, or generally
                  available search engines, e.g., Google or Bing.
                </li>
                <li>
                  • Allow job postings or employment requirements to remain
                  posted on the Site for more than 24 hours after they are no
                  longer viable or valid. To the extent that a position is
                  placed on "hold" or is otherwise not available for immediate
                  placement, the posting must be removed from the Site until
                  such time as the position is a viable opening.
                </li>
                <li>
                  • Engage in spamming, flooding, soliciting or mass marketing
                  via e-mail, direct mail, telephone, or otherwise to MedReps
                  job seekers or subscribers.
                </li>
                <li>
                  • Express or imply that any statements you make are endorsed
                  by us.
                </li>
                <li>
                  • Impersonate any person or entity, including, without
                  limitation, a Company employee or agent, a user of MedReps
                  services, including a candidate, placement specialist or an
                  employer, or otherwise misrepresent your affiliation with any
                  person or entity.
                </li>
                <li>
                  • Respond to a job listing on behalf of anyone other than
                  yourself.
                </li>
                <li>
                  • Delete or revise any material posted by any other person or
                  entity.
                </li>
                <li>
                  • Restrict or inhibit any other user from using and enjoying
                  the Site and services, including, without limitation, by means
                  of "hacking" or defacing any portion of the Site.
                </li>
                <li>
                  • Post or transmit (a) any incomplete, false or inaccurate
                  biographical information or information that is not your own
                  accurate resume (i.e., the resume of a living individual
                  seeking employment on a full-time or part-time basis on his or
                  her own behalf); (b) any content or information that is
                  unlawful, fraudulent, threatening, abusive, libelous,
                  defamatory, obscene or otherwise objectionable or harmful, or
                  that infringes on our or any third party's intellectual
                  property or other rights; (c) any advertisements,
                  solicitations, chain letters, pyramid schemes, investment
                  opportunities, or other unsolicited commercial communication;
                  (d) any information or software that contains a virus, worm,
                  Trojan horse or other harmful or disruptive component; (e) any
                  trade secret of any third party; or (f) any material,
                  nonpublic information about companies without the
                  authorization to do so.
                </li>
                <li>
                  • Post or transmit any job advertisement or posting that does
                  not comply with applicable law, including, but not limited to,
                  United States federal, state and local laws relating to equal
                  employment opportunity and employment eligibility
                  verification; including post or transmit any job advertisement
                  or posting that require a visa as a condition of employment;
                  or that require United States citizenship or lawful permanent
                  residence in the United States as a condition of employment,
                  except when necessary to comply with law, regulation,
                  executive order, or government contract.
                </li>
                <li>
                  • Use the Site for any unlawful commercial, research or
                  information gathering purposes.
                </li>
                <li>
                  • Communicate, send, or place unsolicited e-mail, telephone
                  calls, mailings or other contacts to posting individuals and
                  entities.
                </li>
                <li>
                  • Modify, adapt, sublicense, translate, sell, reverse
                  engineer, decompile or disassemble any portion of the Site.
                </li>
                <li>
                  • Remove any copyright, trademark, or other proprietary rights
                  notices contained in the Site.
                </li>
                <li>• "Frame" or "mirror" any part of the Site.</li>
                <li>
                  • Link to any page of or material on the Site other than the
                  URL located at https://www.medreps.com/
                </li>
                <li>
                  • Use any robot, spider, site search/retrieval application, or
                  other manual or automatic device or process to retrieve,
                  index, "data mine," or in any way reproduce or circumvent the
                  navigational structure or presentation of the Site or its
                  contents.
                </li>
                <li>
                  • Print out or otherwise copy or use any personally
                  identifiable information about candidates for purposes other
                  than consideration of the candidates for potential employment
                  by your company.
                </li>
              </ul>
            </p>
            <p className="t-normal font-sans text-sm">
              MedReps assumes no responsibility or liability for any personnel
              selected by your company. Selection, retention or hire of any
              individual or entity is based solely on your company's
              investigation, verification and determination that such hire is
              suitable for your company's purposes.
            </p>

            <p className="t-normal font-sans text-sm">
              Your company agrees to and will hold the Company harmless from any
              claims, damages or losses incurred by your company or any other
              party as a result of your use of the Company system.
            </p>

            <p className="t-normal font-sans text-sm">
              You agree to comply with all applicable laws, rules and
              regulations in connection with your use of the Site and
              Site-related services.
            </p>

            <p className="t-normal font-sans text-sm">
              The Company has no obligation to monitor the Site or Site-related
              services, including any forum, or any materials that you or other
              third parties transmit or post on to the Site. However, you
              acknowledge and agree that the Company has the right (but not the
              obligation) to monitor the Site and Site-related services,
              including any forum, and the materials you transmit or post, to
              alter or remove any such materials (including, without limitation,
              any posting to the Site), and to disclose such materials and the
              circumstances surrounding their transmission to any third party in
              order to operate the Site properly, to protect itself, its
              sponsors, its registered users and visitors, and to comply with
              legal obligations or governmental requests. The Company reserves
              the right to refuse to post or to remove any information or
              materials, in whole or in part, that it deems in its sole
              discretion to be unacceptable, offensive or in violation of this
              Agreement. The Company also reserves the right to prohibit any
              user who, in the Company's sole discretion, violates the Code of
              Conduct or other terms of this Agreement from using the Site and
              related services. Such prohibition may occur without notice to the
              user.
            </p>
          </div>
          <div className="mb-2" id="restricted_rights_legend">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Restricted Rights Legend
            </p>
            <p className="t-normal font-sans text-sm">
              Use, duplication or disclosure by the Government is subject to the
              restriction as set forth in subparagraph (c)(1)(ii) of the Rights
              in Technical Data and Computer Software Clause as DFARS
              252.227-7013 and FAR 52.227-19, as applicable. Supplier is
              MedReps, a BioSpace, Inc. Company, 3001 Westown Parkway, Suite
              101, West Des Moines, IA 50266.
            </p>
          </div>
          <div className="mb-2" id="making_purchases">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Making Purchases
            </p>
            <p className="t-normal font-sans text-sm">
              If products or services are made available at the Site, and if you
              wish to license or make purchases of products or services
              described on the Site, you may be asked by us or the applicable
              merchant or service provider to supply certain information,
              including but not limited to credit card or other information. If
              you submit such information to us, you understand that any such
              information will be treated by the Company in the manner described
              in our Privacy Policy. You agree that all information that you
              provide to us or any such merchant or service provider will be
              accurate, complete and current. You agree to pay all charges
              incurred by you or any users of your account and credit card or
              other payment mechanism at the prices in effect when such charges
              are incurred. You will also be responsible for paying any
              applicable taxes relating to your purchases. Moreover, you agree
              to review and to comply with the terms and conditions of any
              specific agreement, if any, that you enter into with the merchant
              and/or service provider in connection with the licensing or
              purchase of any product or service.
            </p>
          </div>
          <div className="mb-2" id="submissions">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Submissions
            </p>
            <p className="t-normal font-sans text-sm">
              Because our designees and we host job boards and other forums
              found on the Site and elsewhere and, therefore, redistribute
              materials you give us, we require certain rights in those
              materials. Therefore, by sending or transmitting to us resources,
              information, ideas, notes, concepts, trademarks, service marks or
              other materials (including, but not limited to, job postings)
              (collectively, "Content"), or by posting such Content to any area
              of the Site, you grant us and our designees a worldwide,
              non-exclusive, sub-licensable (through multiple tiers),
              assignable, royalty-free, perpetual, irrevocable right to link to,
              reproduce, distribute (through multiple tiers), adapt, create
              derivative works of, publicly perform, publicly display, digitally
              perform or otherwise use such Content in any media now known or
              hereafter developed. You hereby grant the Company permission to
              display your logo, trademarks and company name on the Site and in
              press and other public releases or filings. Further, by submitting
              Content to the Company, you acknowledge that you have the
              authority to grant such rights to the Company. PLEASE NOTE THAT
              YOU RETAIN OWNERSHIP OF ANY COPYRIGHTS, TRADEMARKS AND SERVICE
              MARKS IN ANY CONTENT YOU SUBMIT.
            </p>
            <p className="t-normal font-sans text-sm">
              None of the Content is endorsed by the Company, and the Company
              cannot and does not make any representations with respect to the
              truth or reliability of the Content. The Company reserves the
              right to remove any Content, in whole or in part, from the Site.
            </p>
            <p className="t-normal font-sans text-sm">
              Communications between the Company and the users of the Site are
              not confidential.
            </p>
          </div>

          <div className="mb-2" id="registeration">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Registration; Use of Secure Areas and Passwords
            </p>
            <p className="t-normal font-sans text-sm">
              Some areas of the Site may require you to register with us. When
              and if you register, you agree to (a) provide accurate, current,
              and complete information about yourself as prompted by our
              registration form (including your e-mail address) and (b) to
              maintain and update your information (including your e-mail
              address) to keep it accurate, current, and complete. You
              acknowledge that should any information provided by you be found
              to be untrue, inaccurate, not current, or incomplete, we reserve
              the right to terminate this Agreement with you and your use of the
              Site.
            </p>
            <p className="t-normal font-sans text-sm">
              As part of the registration process, your username will be your
              email address and you will be asked to select a password. We may
              refuse to allow you to use a username that impersonates someone
              else, may be illegal, may be protected by trademark or other
              proprietary rights law, is vulgar or otherwise offensive, or may
              cause confusion, as determined by us in our sole discretion. You
              will be responsible for the confidentiality and use of your
              password and agree not to distribute, transfer or resell your use
              of or access to the Site to any third party. If more than one
              individual wishes to use a single password belonging to a
              registered user, such registered user must request permission from
              the Company in writing, it being understood that the Company shall
              be under no obligation to approve any such request. If you are a
              job seeker who has reason to believe that your account with us is
              no longer secure, you must promptly change your password by
              updating your account information from the Account/Password
              section of the Site and immediately notifying us of the problem
              via email addressed to info@medreps.com If you are a customer who
              has reason to believe that your account with us is no longer
              secure, you must promptly contact Customer Support via email
              addressed to info@medreps.com. YOU ARE SOLELY AND ENTIRELY
              RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF YOUR USERNAME
              AND PASSWORD AND SOLELY AND ENTIRELY RESPONSIBLE FOR ANY AND ALL
              ACTIVITIES THAT ARE CONDUCTED THROUGH YOUR ACCOUNT.
            </p>
          </div>
          <div className="mb-2" id="claims_infringement">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Claims of Copyright Infringement
            </p>
            <p className="t-normal font-sans text-sm">
              The Digital Millennium Copyright Act of 1998 (the "DMCA") provides
              recourse for copyright owners who believe that material appearing
              on the Internet infringes their rights under U.S. copyright law.
              If you have a good faith belief that materials hosted by us
              infringe your copyright, you (or your agent) may send us a notice
              requesting that the material be removed, or access to it blocked.
              The notice must include the following information: (a) a physical
              or electronic signature of a person authorized to act on behalf of
              the owner of an exclusive right that is allegedly being infringed
              upon; (b) identification of the copyrighted work claimed to have
              been infringed upon (or if multiple copyrighted works located on
              the Site are covered by a single notification, a representative
              list of such works); (c) identification of the material that is
              claimed to be infringing or the subject of infringing activity,
              and information reasonably sufficient to allow us to locate the
              material on the Site; (d) the name, address, telephone number, and
              e-mail address (if available) of the complaining party; (e) a
              statement that the complaining party has a good faith belief that
              use of the material in the manner complained of is not authorized
              by the copyright owner, its agent, or the law; and (f) a statement
              that the information in the notification is accurate, and under
              penalty of perjury, that the complaining party is authorized to
              act on behalf of the owner of an exclusive right that is allegedly
              infringed. If you believe in good faith that a notice of copyright
              infringement has been wrongly filed against you, the DMCA permits
              you to send us a counter-notice. Notices and counter-notices must
              meet the then-current statutory requirements imposed by the DMCA;
              see http://www.loc.gov/copyright/ for details. Notices and
              counter-notices with respect to the Site should be sent to
              MedReps, a BioSpace, Inc. company, 3001 Westown Parkway, Suite
              101, West Des Moines, IA USA 50266, (tel) 866-619-1629, Attention:
              General Counsel. We suggest that you consult your legal advisor
              before filing a notice or counter-notice. Also, be aware that
              there can be penalties for false claims under the DMCA.
            </p>
          </div>

          <div className="mb-2" id="miscellaneous">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Miscellaneous
            </p>
            <p className="t-normal font-sans text-sm">
              This Agreement is entered into in the State of Iowa and shall be
              governed by and construed in accordance with the laws of the State
              of Iowa, exclusive of its choice of law rules. Each party to this
              Agreement hereby submits to the exclusive jurisdiction of the
              state and federal courts sitting in the County of Polk in the
              State of Iowa for any dispute arising under or in connection with
              this Agreement, the Site or any Site-related services, and waives
              any jurisdictional, venue or inconvenient forum objections to such
              courts. In any action to enforce this Agreement, the prevailing
              party will be entitled to costs and attorneys' fees. In the event
              that any of the provisions of this Agreement shall be held by a
              court or other tribunal of competent jurisdiction to be
              unenforceable, such provisions shall be limited or eliminated to
              the minimum extent necessary so that this Agreement shall
              otherwise remain in full force and effect and enforceable. Failure
              of any party to insist upon strict compliance with any of the
              terms and conditions of this Agreement shall not be deemed a
              waiver or relinquishment of any similar right or power at any
              subsequent time.
            </p>
            <p className="t-normal font-sans text-sm">
              This Agreement constitutes the entire agreement between the
              parties hereto pertaining to the subject matter hereof, and any
              and all written or oral agreements heretofore existing between the
              parties hereto are expressly canceled. This Agreement is not
              assignable, transferable or sub-licensable by you except with
              prior written consent. Any heading, caption or section title
              contained in this Agreement is inserted only as a matter of
              convenience and in no way defines or explains any section or
              provision hereof. The Company reserves the right in its sole
              discretion to terminate the use of the Site by a user at any time.
            </p>
          </div>

          <div className="mb-2" id="legal_notices">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Legal Notices
            </p>
            <p className="t-normal font-sans text-sm">
              Under California Civil Code Section 1789.3, California residents
              are entitled to the following specific consumer rights
              information:
            </p>
            <p className="t-normal font-sans text-sm">
              Pricing Information: Current rates for our services may be
              obtained by calling us at 866-619-1629 or by sending an e-mail to
              info@medreps.com. We reserve the right to change fees, surcharges,
              monthly or other periodic subscription fees or to institute new
              fees at any time as provided in this Agreement.
            </p>
            <p className="t-normal font-sans text-sm">
              Complaints: The Complaint Assistance Unit of the Division of
              Consumer Services of the California Department of Consumer Affairs
              may be contacted in writing at 400 R Street, Suite 1080,
              Sacramento, CA 95814, or by telephone at (916) 445-1254 or (800)
              952-5210.
            </p>
          </div>
          <div className="mb-2" id="additional_terms">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Additional Terms for Use of Additional Services
            </p>
            <p className="t-normal font-sans text-sm">
              If you utilize MedReps’ recruitment service(s), candidate
              subscription service(s), or other advertising or consulting
              service(s) these additional terms apply to those services:
            </p>
          </div>
          <div className="mb-2" id="advertisers">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Advertisers (Employers and Recruiters)
            </p>
            <ol>
              <li>
                1. Each Advertiser is permitted one Account Owner and up to four
                additional registered users, each with a unique username and
                password. Each password is for the sole use of a single
                registered user of MedReps.com and is not transferable.
                Additional user licenses can be purchased as needed. Sharing of
                login and password between users is not permitted.
              </li>
              <li>
                2. All jobs posted on MedReps.com must be "real" jobs, currently
                available, that the Advertiser is actively seeking to fill. Job
                details must be accurate at the time of posting. All contents of
                advertisements are subject to the approval of MedReps.com.
                MedReps.com reserves the right to reassign postings placed in
                the wrong category and make other corrections to postings if
                necessary. MedReps.com may also archive, reject, or cancel any
                advertiser, advertisement, job posting or URL link, at any time,
                for any reason.
              </li>
              <li>
                3. Without limiting MedReps.com’s Terms of Use, MedReps.com
                reserves the right to reject any job posting that does not
                comply with applicable local, national and international laws,
                including, but not limited to, laws related to labor and
                employment, equal employment opportunity and employment
                eligibility requirements, data privacy, data access and use, and
                intellectual privacy; any franchise, pyramid scheme, “club
                membership,” distributorship or sales representative agency
                arrangement; any business opportunity which requires an up front
                or periodic payment, pays commissions only (unless the posting
                specifically states the position is commission only and the
                product/service is clearly defined), or requires recruitment of
                other members, sub-distributors or sub-agents.
              </li>
              <li>
                4. The job posting and candidate database features may be used
                only for lawful purposes by individuals seeking employment and
                career information and employers seeking employees. MedReps.com
                specifically prohibits any other use of the site, and you agree
                not to do any of the following: (a) post jobs or content that
                contain "hidden" keywords or any keywords that are irrelevant to
                the job or are otherwise misleading contained in a job posting;
                (b) use the candidate database for any purpose other than as an
                employer seeking employees, including but not limited to using
                the information to sell or promote any products or services; (c)
                post or submit any incomplete, false or inaccurate biographical
                information or information which is not your own; (d) post any
                franchise, pyramid scheme, "club membership", distributorship or
                sales representative agency arrangement or other business
                opportunity which requires an up-front or periodic payment; (e)
                send unsolicited mail or e-mail, make unsolicited phone calls or
                send unsolicited faxes regarding promotions and/or advertising
                of products or services to a Member or other user; (f) delete or
                revise any material posted by any other person or entity; (g)
                use or attempt to use any engine, software, tool, agent or other
                device or mechanism (including without limitation browsers,
                spiders, robots, avatars or intelligent agents) to navigate or
                search MedReps.com other than the search engine and search
                agents available from MedReps.com and other than generally
                available third party web browsers (e.g., Google Chrome, Safari,
                Firefox or Internet Explorer); (h) attempt to decipher,
                decompile, disassemble or reverse engineer any of the software
                comprising or in any way making up a part of the site; or (i)
                aggregate, copy or duplicate in any manner any of the content or
                information available from the site.
              </li>
              <li>
                5. Advertisers are limited to posting 10 independent
                representative jobs per account. Independent jobs that are not
                categorized as such will be reassigned to the correct category.
                Advertiser accounts may be subject to implementation of a job
                posting limit if the advertiser does not comply.
              </li>
              <li>
                6. By posting a job on MedReps.com you agree to allow
                MedReps.com to use your logo in conjunction with promoting your
                jobs.
              </li>
              <li>
                7. By submitting advertising or job postings for inclusion on
                MedReps.com the Advertiser agrees to be bound by the terms of
                this agreement. These Terms of Use are a contract supersedes all
                terms and conditions and any previous agreements between
                MedReps.com and Advertiser.
              </li>
              <li>
                8. Your subscription with MedReps will start on the date of
                purchase and will auto-renew according to the schedule of the
                product you selected (one month, six months, or one year). All
                memberships purchased at a discounted rate will be renewed at
                the standard price.
              </li>
              <li>
                9. All payments must be received prior to the posting of any
                jobs or advertisements. Checks, American Express, Visa and
                MasterCard are accepted for payment. Check payments not honored
                by financial institutions are voided and a $50.00 fee is added
                to the amount due for the current billing cycle. All payments
                more than 30 days past due will bear interest at the rate of one
                and one-half percent (1.5%) per month from the date of invoice
                or the due date, whichever is later.
              </li>
              <li>
                10. Changes, cancellations, and refunds: You can change your
                payment method via your MedReps account. If a payment is not
                successfully settled, due to expiration, insufficient funds, or
                otherwise, and you do not notify us or cancel your account, we
                may suspend your service until we have obtained a valid,
                up-to-date payment.
                <br />
                You can cancel your subscription service at any time by visiting
                the Account page and selecting “Cancel Subscription” in your
                MedReps account. Written notice to cancel can be sent
                to info@medreps.com. All requests for cancellation must be
                received at least 1 business day prior to renewal.
                <br />
                Upon cancellation, you will continue to have access to services
                thru the end of your contract period. We do not provide refunds
                or credits for any partial-service periods that you do not use
                the service.
              </li>
              <li>
                11. You may incur an initial set-up fee to being your
                subscription service.  This fee is non-refundable.{' '}
              </li>
              <li>
                12. MedReps.com may also cancel your membership and, therefore,
                these Terms of Use with respect to you, if you fail to make any
                payment when due or become bankrupt or insolvent. These Terms of
                Use will remain in full force and effect while you are a Member
                or user on MedReps.com at any level. If MedReps.com cancels a
                membership due to breach of these Terms of Use or failure to
                make payments, the Member will not be entitled to any refund of
                fees paid. We reserve the right, at our sole discretion, to
                pursue all of our legal remedies, including but not limited to
                deletion of your user content from the site and immediate
                termination of your registration with or ability to access
                MedReps.com.
              </li>
            </ol>
          </div>

          <div className="mb-2" id="advertising_campaigns">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Advertising Campaigns
            </p>
            <ol>
              <li>
                1. The price for any service shall be the price stated on the
                order form. The prices stated are exclusive of any taxes, fees,
                duties, licenses or levies ("Taxes") now or hereinafter imposed
                upon the Product or Service. You agree to participate as set
                forth on the order form for the campaign(s) indicated.
                Participants have no right to assign media services or
                participation without the prior written consent of MedReps. No
                refunds or transfers permitted.
              </li>
              <li>
                2. PAYMENT IS DUE IN FULL PRIOR TO MEDREPS SENDING THE MATERIALS
                TO PRINT OR PUBLISH. You will be responsible for the price of
                all services substantially conforming to your signed order form,
                notwithstanding that you may not have accepted, or may have
                revoked acceptance, of same.  All sums paid to MedReps are
                non-refundable and shall be retained by MedReps.
              </li>
              <li>
                3. MedReps reserves the right, in its sole discretion, to change
                or cancel the media campaign and the content of such thereof as
                per the needs and/or convenience of MedReps. In the case of
                cancellation, MedReps will fully refund any fees paid directly
                toward that media campaign. MedReps also reserves the right to
                suspend, cancel, or postpone any media campaign in the event of
                unavoidable occurrences (e.g., fire, earthquake, etc.).
              </li>
              <li>
                4. You represent that you own or have all rights necessary to
                transmit the required materials for participation in any media
                campaign ("Content") to MedReps. Furthermore, you hereby grant
                MedReps a perpetual license to use the Content on the site,
                print and electronic materials.
              </li>
              <li>
                5. You acknowledge and agree that you are solely responsible for
                the form, content and accuracy of any campaign that you have
                approved, in proof form.  You agree to review campaign proofs
                carefully.  Errors or omissions in campaigns that have been
                approved by you will not be reprinted or republished unless
                agreed to in writing by MedReps, at its sole discretion.
              </li>
            </ol>
          </div>

          <div className="mb-2" id="candidates">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Candidates (collectively called Members)
            </p>
            <ol>
              <li>
                1. By signing up for job alerts or registering with MedReps.com,
                even if you do not complete the payment process to establish an
                active membership, you agree to receive email messages from us
                directly. MedReps.com will enable non-active Members the
                opportunity to opt out of our email lists in one click, with
                clear directions contained within each message.
              </li>
              <li>
                2. The candidate profile and job application features may be
                used only for lawful purposes by individuals seeking employment
                and career information. MedReps.com specifically prohibits any
                other use of the site, and you agree not to do any of the
                following: (a) post content that contains "hidden" keywords or
                any keywords that are irrelevant or are otherwise misleading
                contained in a resume; (b) post or submit any incomplete, false
                or inaccurate biographical information or information which is
                not your own; (c) post any franchise, pyramid scheme, "club
                membership", distributorship or sales representative agency
                arrangement or other business opportunity which requires an
                up-front or periodic payment; (d) send unsolicited mail or
                e-mail, make unsolicited phone calls or send unsolicited faxes
                regarding promotions and/or advertising of products or services
                to a Member or other user; (e) delete or revise any material
                posted by any other person or entity; (f) use or attempt to use
                any engine, software, tool, agent or other device or mechanism
                (including without limitation browsers, spiders, robots, avatars
                or intelligent agents) to navigate or search MedReps.com other
                than the search engine and search agents available from
                MedReps.com and other than generally available third party web
                browsers (e.g., Google Chrome, Safari, Firefox or Internet
                Explorer); (g) attempt to decipher, decompile, disassemble or
                reverse engineer any of the software comprising or in any way
                making up a part of the site; or (h) aggregate, copy or
                duplicate in any manner any of the content or information
                available from the site.
              </li>
              <li>
                3. Membership changes, cancellations, and refunds: Candidate
                Members may cancel membership and, therefore, these Terms of Use
                by emailing the MedReps.com customer service team
                at info@MedReps.com or by clicking “Cancel subscription” in your
                account management area on MedReps.com. <br />
                If you cancel a membership within 1 business day from the date
                of purchase, MedReps.com will provide a full refund upon
                request. Requests may be sent to info@medreps.com, and must be
                made within 5 business days of the cancellation. If however, you
                cancel a membership after the 1 day window following the
                purchase, you will not be entitled to any refund of fees.
                <br />
                All Candidate memberships will automatically renew unless
                cancellation is received at least one business day prior to the
                renewal date. Notice of cancellation can be delivered to our
                customer service team via email to info@MedReps.com or by
                clicking the “Cancel subscription” link in the account
                management section of the site. All memberships purchased at a
                discounted rate will be renewed at the standard price.
              </li>
              <li>
                4. MedReps.com may also cancel your membership and, therefore,
                these Terms of Use with respect to you, if you fail to make any
                payment when due or become bankrupt or insolvent. These Terms of
                Use will remain in full force and effect while you are a Member
                or user on MedReps.com at any level. If MedReps.com cancels a
                membership due to breach of these Terms of Use or failure to
                make payments, the Member will not be entitled to any refund of
                fees paid. We reserve the right, at our sole discretion, to
                pursue all of our legal remedies, including but not limited to
                deletion of your user content from the site and immediate
                termination of your registration with or ability to access
                MedReps.com.
              </li>
            </ol>
          </div>
        </div>
        <h1
          className="px-6 sm:px-0 font-playfair font-headingOne font-semibold text-merlot pt-4"
          id="cookie-policy"
        >
          Cookie Policy
        </h1>
        <div className="mb-2 px-6 sm:px-0">
          <p className="t-normal font-sans text-sm">
            MedReps.com ("we" or "us" or "our") respects the privacy of our
            users ("user" or "you"). This Cookie Policy explains how we collect,
            use, disclose, and safeguard your information when you visit our
            website MedReps.com including any other media form, media channel,
            mobile website, or mobile application related or connected thereto
            (collectively, the "Site"). Please read this Cookie Policy
            carefully. By using the Site, you consent to the data and privacy
            practices described in this policy as well as the general Terms of
            Use. IF YOU DO NOT AGREE WITH THE TERMS OF THIS COOKIE POLICY,
            PLEASE DO NOT ACCESS THE SITE.
          </p>
          <p className="t-normal font-sans text-sm">
            We reserve the right to make changes to this Cookie Policy at any
            time and for any reason. We will alert you about any changes by
            updating the "Revised" date of this Cookie Policy. Any changes or
            modifications will be effective immediately upon posting the updated
            Cookie Policy on the Site, and you waive the right to receive
            specific notice of each such change or modification. You are
            encouraged to periodically review this Cookie Policy to stay
            informed of updates. You will be deemed to have been made aware of,
            will be subject to, and will be deemed to have accepted the changes
            in any revised Cookie Policy by your continued use of the Site after
            the date such revised Cookie Policy is posted.
          </p>
          <p className="t-question font-sans font-semibold text-lg">
            We Use Tracking Technologies For a Variety of Reasons
          </p>
          <ul className="privacy-page-list pl-0 ml-0 font-sans text-sm">
            <li>To recognize new visitors to our web site.</li>
            <li>To recognize past customers.</li>
            <li>To store your password if you are registered on our site.</li>
            <li>
              To improve our website and better understand your visits on our
              platforms.
            </li>
            <li>To integrate with third party social media websites.</li>
            <li>
              To serve you with interest-based or targeted advertising (see
              below for more on interest-based advertising).
            </li>
            <li>
              To observe your behaviors and browsing activities over time across
              multiple websites or other platforms.
            </li>
            <li>
              To better understand the interests of our customers and our
              website visitors.
            </li>
          </ul>
          <div className="mb-2">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              We Engage in Interest-Based Advertising
            </p>
            <p className="t-normal font-sans text-sm">
              We and our partners display interest-based advertising using
              information gathered about you over time across multiple websites
              or other platforms. This might include apps.
            </p>
            <p className="t-normal font-sans text-sm">
              Interest-based advertising or “online behavioral advertising”
              includes ads served to you after you leave our website,
              encouraging you to return. They also include ads we think are
              relevant based on your browsing habits or online activities. These
              ads might be served on websites or on apps. They might also be
              served in emails. We might serve these ads, or third parties may
              serve ads. They might be about our products or other companies’
              products. Where legally required we get consent to engage in
              interest-based advertising.
            </p>
          </div>
          <div className="mb-2">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              You Can Control Certain Tracking Tools
            </p>
            <p className="t-normal font-sans text-sm">
              Your browser may give you the ability to control cookies. How you
              do so depends on the type of cookie. Certain browsers can be set
              to reject browser cookies. Each browser is different. To change
              your cookie settings visit the website for the browser you are
              using.
            </p>
          </div>
          <div className="mb-2">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              Our Do Not Track Policy
            </p>
            <p className="t-normal font-sans text-sm">
              Some browsers have a “Do Not Track” feature that allows you to
              tell a web site not to track you. These features are not all
              uniform. We do not currently respond to those signals. If you
              block cookies, certain functionality on our sites may not work. If
              you block or reject cookies, not all of the tracking described
              here will stop. Certain options you select are browser and device
              specific.
            </p>
          </div>
          <div className="mb-2">
            <p className="t-question font-sans font-semibold text-lg mb-1">
              We Use Specific Tracking Technologies
            </p>
            <p className="t-normal font-sans text-sm">
              Here is more information about the tracking technologies and
              cookies we currently use on MedReps.com. We use all of these
              tracking technologies to improve our site and the experience of
              our visitors:
            </p>
            <ul className="privacy-page-list pl-0 ml-0 font-sans text-sm">
              <li>
                <b>Adobe (Satellite):</b> Leverages browser caching for the
                adobe analytics satellite.js files.
              </li>
              <li>
                <b>AdRoll:</b>
                Leverages browser caching for the adobe analytics satellite.js
                files.
              </li>
              <li>
                <b>Bing Ads:</b>
                Bing Ads allows us to have our advertisements appear at the top
                of the results page for certain search engines. We also use this
                tool to track visitors that click on our ads and visit our site.
                This helps us direct users to our website and determine the
                effectiveness of our online campaigns in terms of sales and user
                activity on our sites.
              </li>
              <li>
                <b>Facebook:</b> The new Facebook pixel makes conversion
                tracking, optimization and remarketing easier than ever. Use the
                new pixel with standard events and you’ll be able to access all
                features below.
                <ul className="privacy-inner-list font-sans text-sm">
                  <li>
                    <b>Conversion tracking.</b> See how successful your ad is by
                    seeing what happened as a direct result of your ad.
                  </li>
                  <li>
                    <b>Optimization.</b> Automatically bid for conversions after
                    you set up your pixel.
                  </li>
                  <li>
                    <b>Remarketing.</b> Remarket to everyone who visits your
                    site, or just to people who visit specific pages or take
                    specific actions.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <p className="t-normal font-sans text-sm mb-1">
            <b>Facebook Custom Audience:</b> We use Facebook Custom Audience to
            deliver ads on Facebook to a certain group of users.
          </p>
          <p className="t-normal font-sans text-sm mb-1">
            <b>Google AdWords Conversion:</b> We use conversion tracking to help
            us understand how effective our digital campaigns are.
          </p>
          <p className="t-normal font-sans text-sm mb-1">
            <b>Google Analytics:</b> We use Google Analytics to collect
            information about how visitors use our website. For example, we
            collect details of the site where the visitor has come from and the
            total number of times a visitor has been to our website. This allows
            us to determine the effectiveness of our online campaigns in terms
            of sales and user activity on our sites.
          </p>
          <p className="t-normal font-sans text-sm mb-1">
            <b>Google Dynamic Remarketing:</b> We use these tracking tools to
            track user behavior over time and across third party sites to
            improve the effectiveness of our online advertising. We collect
            information about what ads users view and whether they click on the
            ads. We use this information to improve and customize our
            advertising.
          </p>
          <p className="t-normal font-sans text-sm mb-1">
            <b>Google (API):</b> Written by Google, this compact and efficient
            client library provides access to Google REST APIs.
          </p>
          <p className="t-normal font-sans text-sm mb-1">
            <b>Google + API:</b> Used to create a more engaging experience and
            connect with more users by integrating social into your web site.
          </p>
          <p className="t-normal font-sans text-sm mb-1">
            <b>Google Ads:</b> Connect with the right people, in the right
            moments, with DoubleClick's integrated solutions. Used for Digital
            Marketing and Revenue Management.
          </p>
          <p className="t-normal font-sans text-sm mb-1">
            <b>Google Tag Manager:</b> We use Google Tag Manager to measure what
            features on our site are interesting to our users. It also helps us
            understand what portions of our site users clicked on during a
            certain time and how users arrived on our site. This allows us to
            determine the effectiveness of our online campaigns in terms of
            sales and user activity on our sites.
          </p>
          <p className="t-normal font-sans text-sm mb-1">
            <b>gTag (Google):</b> gtag.js is a new web tagging library that
            replaces the AdWords website conversion tracking tag and the
            remarketing tag. Conversion tracking and remarketing functionalities
            are now combined into a single tag. In addition, gtag.js can be used
            to send data to some other Google products, including Google
            Analytics.
          </p>
          <p className="t-normal font-sans text-sm mb-1">
            <b>HotJar:</b> Hotjar Tracking Code for MedReps.com. Hotjar is a new
            and easy way to truly understand your web and mobile site visitors.
          </p>
          <p className="t-normal font-sans text-sm mb-1">
            <b>Sumo:</b> Sumo arms you with a suite of tools to increase your
            traffic, build a following and track your success every step of the
            way. Get up and running in 37 seconds to start converting your
            visitors into lifelong customers.
          </p>
          <p className="t-normal font-sans text-sm mb-1">
            <b>LinkedIn (Insight):</b> We use the LinkedIn Insight Tag to track
            conversions, retarget website visitors, and unlock additional
            insights about members interacting with your ads.
          </p>
          <p className="t-normal font-sans text-sm mb-1">
            <b>Pardot (Salesforce):</b> Pardot, B2B marketing automation by
            Salesforce, offers a marketing automation solution that allows
            marketing and sales departments to create, deploy, and manage online
            marketing campaigns from one central platform.
          </p>
          <p className="t-normal font-sans text-sm mb-1">
            <b>Webenage:</b> B2C marketing automation software helps in customer
            retention & user engagement across all channels with Journey
            Designer.
          </p>
        </div>
        <p className="t-normal px-6 sm:px-0 font-sans text-sm mt-8">
          Please inform MedReps.com of any inaccuracies in regards to the
          information posted on this site by e-mailing info@MedReps.com.
        </p>

        <div className="mb-2 block sm:hidden px-6" id="pricing">
          <p className="t-question font-sans font-semibold text-lg mb-1">
            Pricing
          </p>
          <div className="flex flex-wrap justify-between">
            <div className="w-full sm:w-30%">
              <p className="font-sans text-sm italic mb-4">
                Candidate Membership Packages:
              </p>
              <div>
                <ul className="privacy-page-list pl-0 ml-0 font-sans text-sm">
                  <li>$19.99 – 1 month access</li>
                  <li>$29.99 - 3 month access</li>
                  <li>$52.99 - 6 month access</li>
                  <li>Resume Review Service (Professional) - $197.00</li>
                  <li>Cover Letter Edit Service - $55.00</li>
                  <li>Interview Coaching - $200.00</li>
                  <li>
                    Bundles
                    <ul className="privacy-inner-list font-sans text-sm">
                      <li>
                        Resume Rewrite with 1-hour Interview Coaching Session -
                        $415.00
                      </li>
                      <li>
                        Professional Resume Rewrite with Cover Letter - $280.00
                      </li>
                    </ul>
                  </li>
                  <li>
                    Sales Training Packages
                    <ul className="privacy-inner-list font-sans text-sm">
                      <li>Basic Training for Med Reps -- $79.95</li>
                      <li>Deluxe Training for Med Reps -- $99.95</li>
                      <li>Pharmaceutical Sales Training -- $129.95</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full sm:w-30%">
              <p className="font-sans text-sm italic mb-4">
                Employer Membership Packages:
              </p>
              <div>
                <ul className="privacy-page-list pl-0 ml-0 font-sans text-sm">
                  <li>Employer 30 Day Single Job Package: $345</li>
                  <li>Employer 30 Day Unlimited Package: $525</li>
                  <li>Employer 60 Day Single Job Package: $470</li>
                  <li>Employer 60 Day Unlimited Package: $675</li>
                  <li>Employer 6 Month Unlimited Package: $1575</li>
                  <li>Employer Annual Unlimited Package: $2625</li>
                </ul>
                <p className="font-sans text-sm italic mb-4">
                  Recruiter Membership Packages:
                </p>
                <ul className="privacy-page-list pl-0 ml-0 font-sans text-sm">
                  <li>Recruiter - $375 --- 6 Month Membership</li>
                  <li>Recruiter - $650 --- Annual membership</li>
                </ul>
              </div>
            </div>
            <div className="w-full sm:w-30%">
              <p className="font-sans text-sm italic mb-4">
                Non-Membership Services:
              </p>
              <div>
                <ul className="privacy-page-list pl-0 ml-0 font-sans text-sm">
                  <li>Resume Review Service (Professional) - $245.00</li>
                  <li>Resume Review Service (Executive) - $425.00</li>
                  <li>Cover Letter Edit Service - $55.00</li>
                  <li>Interview Coaching - $200.00</li>
                  <li>Bundles</li>
                  <li>Interview Coaching - $200.00</li>
                  <li>
                    Bundles
                    <ul className="privacy-inner-list font-sans text-sm">
                      <li>
                        Resume Rewrite with 1-hour Interview Coaching Session -
                        $415.00
                      </li>
                      <li>
                        Professional Resume Rewrite with Cover Letter - $280.00
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PrivacyPolicyComponent
