import React from 'react'
import PrivacyPolicyComponent from '../components/pageComponents/privacyPolicy'
import Wrapper from '../components/wrapper'

const PrivacyPolicyPage = ({location}) => {
  return (
    <Wrapper location={location} title="Privacy Policy | MedReps"
      description="Protecting your privacy is our top priority. Our Privacy Policy page outlines how we collect, use and protect your personal information. Medreps is committed to being transparent about our data practices and ensuring that your information is handled securely and responsibly.">
      <PrivacyPolicyComponent />
    </Wrapper>
  )
}
export default PrivacyPolicyPage
